import CheckBrowser from '@/vue_components/CheckBrowser/CheckBrowser'
import { createVueApp } from '@/vue_components/create_vue_app'

export const checkBrowser = () => {
  if (!Utils.isInvalidBrowser()) { return }

  createVueApp({
    el: '#vue_check_browser',
    name: 'CheckBrowserRoot',
    render: (h) => h(CheckBrowser),
  })

  throw new Error('Your browser not supported')
}
